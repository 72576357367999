body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/*
.BaseTable :not(.BaseTable__table-frozen-left) .BaseTable__row-cell > div {
  overflow-y: scroll ;
}

.BaseTable__table-frozen-left .BaseTable__body {
  overflow-y: hidden !important;
} 
.BaseTable .BaseTable__row-cell > div > div{
  -webkit-mask-image: none;
}

*/
#modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  top:0;
  left: 0;
  
}

#modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

#modal-container {
  width: 80vw;
  height: 80vh;
  background: rgba(255,255,255,8);
  color: black;
  border-radius: 10px;
  position: relative;
  padding: 50px;
  overflow: scroll;

}
h3 {
  margin-bottom: 0;
}
#modal-subheader {
  margin: 0;
  padding: 0;
}

#modal-header i {
  font-size: 20px;
}
#modal-close {
  position: absolute;
  right: 15px;
  top: 15px;
  outline: none;
  appearance: none;
  color: black;
  width: 25px;
  height: 25px;
  background: none;
  border: 0px;
  font-weight: bold;
  cursor: pointer;
}

#modal-close svg {width: inherit; height: inherit;}

#modal-content p {
  border: 1px solid black;
  margin: 10px 10px 0 0;
  padding: 5px;
}
.row {
  display: flex;
  flex-wrap: wrap;
}

.col-8 {
  flex:66%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex:33%;
}
.col-6 {
  flex:50%;
}
.col-12 {
  flex:100%;
}


.chart-container {
  margin: 10px;
}
.chart-container .back {
  stroke: #CCC;
  stroke-width: 10;
}
.chart-container .front {
  stroke: #000;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-dasharray: 395.84;
  transform: rotate(-90deg);
  transform-origin: center;
  animation: fill 2s reverse;
}
.chart-container .text {
  font-size: 20px;
  fill: #000;
  transform: translateY(0);
}
